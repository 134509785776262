import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Header from '../components/Header';
import Dentists from './Dentists';
// import Patients from './Patients';
import Updates from './Updates';

export default function() {
  return (
    <Router>
      <Header />

      <Switch>
        <Route path="/dentists">
          <Dentists />
        </Route>
        {/* <Route path="/patients">
          <Patients />
        </Route> */}
        <Route path="/updates">
          <Updates />
        </Route>
      </Switch>
    </Router>
  );
}
