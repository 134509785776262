import styled from 'styled-components';
import { Col, Row } from 'antd';

export default {
  RowStyled: styled(Row)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  ColStyled: styled(Col)`
    padding: 40px 0;
  `,
  ContentWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  TableHeader: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  `,
  LatestUpdateWrapper: styled.div`
    display: flex;
    justify-self: flex-end;
    justify-content: center;
    align-items: center;
  `,
};
