export function getDurationFromMilliseconds(rawMilliseconds) {
  const milliseconds = Math.round(rawMilliseconds);
  const seconds = (milliseconds / 1000).toFixed(1);
  const minutes = (milliseconds / (1000 * 60)).toFixed(1);
  const hours = (milliseconds / (1000 * 60 * 60)).toFixed(1);

  let distance;
  if (milliseconds < 1000) {
    distance = `${milliseconds} ms`;
  } else if (seconds < 60) {
    distance = `${seconds} sec`;
  } else if (minutes < 60) {
    distance = `${minutes} min`;
  } else if (hours >= 1) {
    distance = `${hours} hr`;
  }

  return {
    distance,
    milliseconds,
  };
}
