import React, { useEffect, useState } from 'react';
import { Table, Typography, Tag } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';

import { getDentists } from '../../../modules';

import styles from './styles';

const { Title } = Typography;

export default function() {
  // navigation
  const { path } = useRouteMatch();

  // state
  const [dentists, setDentists] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);

      let newDentists = await getDentists();

      if (newDentists.length > 0) {
        newDentists = newDentists.map((object, index) => ({
          ...object,
          key: index + 1,
        }));

        setDentists(newDentists);
      }

      setFetching(false);
    };

    document.title = 'Dentists | BruxRelief';

    fetchData();
  }, []);

  const columns = [
    {
      title: 'UID',
      dataIndex: 'uid',
      key: 'uid',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Assessments Performed',
      dataIndex: 'assessmentsPerformed',
      key: 'assessmentsPerformed',
      render: (assessmentsPerformed, { email }) =>
        assessmentsPerformed > 0 ? (
          <Link to={`${path}/assessments/${email}`}>
            {assessmentsPerformed}
          </Link>
        ) : (
          <span>{assessmentsPerformed}</span>
        ),
      sorter: (a, b) => a.assessmentsPerformed - b.assessmentsPerformed,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Subscription',
      dataIndex: 'hasSubscription',
      key: 'hasSubscription',
      render: hasSubscription => (
        <span>
          <Tag color={hasSubscription ? 'green' : 'volcano'}>
            {hasSubscription ? 'Has subscription' : 'No subscription'}
          </Tag>
        </span>
      ),
    },
    {
      title: 'Sessions Performed',
      dataIndex: 'sessionsPerformed',
      key: 'sessionsPerformed',
    },
    {
      title: 'Staff Email',
      dataIndex: 'staffEmail',
      key: 'staffEmail',
    },
  ];

  return (
    <styles.RowStyled>
      <Title level={2} style={{ margin: '75px 0 50px 0' }}>
        {'Dentists'}
      </Title>
      <styles.ColStyled span={18}>
        <styles.ContentWrapper>
          <Table
            columns={columns}
            dataSource={dentists}
            pagination={false}
            loading={fetching}
            style={{ width: '100%' }}
          />
        </styles.ContentWrapper>
      </styles.ColStyled>
    </styles.RowStyled>
  );
}
