import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Checkbox,
  Input,
  Upload,
  message,
  Icon,
  Typography,
} from 'antd';
import axios from 'axios';
import semver from 'semver';

import styles from './styles';

import { API_URL } from '../../../config';

const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    sm: { span: 10 },
  },
  wrapperCol: {
    sm: { span: 8 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      offset: 10,
      span: 8,
    },
  },
};

export default function() {
  const defaultChannels = ['Beta', 'Production'];

  const [version, setVersion] = useState('');
  const [channels, setChannels] = useState([]);
  const [file, setFile] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {
    document.title = 'Upload Firmware | BruxRelief';
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();

    setFormLoading(true);

    if (version === '' || channels.length === 0 || !file) {
      message.error('You missed something.');
      setFormLoading(false);
      return;
    } else if (!semver.valid(version)) {
      message.error('Wrong firmware version.');
      setFormLoading(false);
      return;
    } else if (!file.name.includes('zip')) {
      message.error('Wrong file type provided, needed (.zip).');
      setFormLoading(false);
      return;
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const data = new FormData();

    data.append('version', version);
    data.append('update', file);
    data.append('channels', JSON.stringify(channels));

    try {
      await axios.post(`${API_URL}/dfu/upload`, data, config);

      setFormSubmitted(true);
    } catch (error) {
      console.warn(error);

      if (error.data) {
        message.error(
          error && error.response ? error.response.data : error.toString()
        );
      }
    } finally {
      setFormLoading(false);
    }
  };

  const handleFileUpload = ({ file, onSuccess }) => {
    setFile(file);

    setTimeout(() => {
      onSuccess();
    }, 0);
  };

  const handleFileRemove = () => {
    setFile(null);

    return true;
  };

  const renderForm = () => (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label="Version" required>
        <Input
          value={version}
          onChange={event => setVersion(event.target.value)}
          style={{ width: 152 }}
        />
      </Form.Item>
      <Form.Item label="Channels" required={true}>
        <Checkbox.Group
          options={defaultChannels}
          value={channels}
          onChange={checkedList => setChannels(checkedList)}
        />
      </Form.Item>
      <Form.Item label={'Firmware(zip file)'} required={true}>
        <Upload
          name="update"
          customRequest={handleFileUpload}
          fileList={file ? [file] : []}
          onRemove={handleFileRemove}
        >
          <Button>
            <Icon type="upload" />
            {'Click to Upload'}
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item {...tailFormItemLayout} style={{ marginBottom: 0 }}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={formLoading}
          loading={formLoading}
        >
          {'Submit'}
        </Button>
      </Form.Item>
    </Form>
  );

  const renderSuccessfulUpload = () => (
    <styles.ContentWrapper>
      <Icon
        type="check-circle"
        theme="twoTone"
        twoToneColor="#52c41a"
        style={{ fontSize: 48 }}
      />
      <Title level={4} style={{ marginTop: 20 }} type="secondary">
        {'Uploaded Successfully'}
      </Title>
      <Button
        type="primary"
        style={{ marginTop: 15 }}
        onClick={() => setFormSubmitted(false)}
      >
        {'Go back'}
      </Button>
    </styles.ContentWrapper>
  );

  return (
    <styles.RowStyled>
      <Title level={2} style={{ margin: '75px 0 50px 0' }}>
        {'Upload new Firmware'}
      </Title>
      <styles.ColStyled span={8}>
        {formSubmitted ? renderSuccessfulUpload() : renderForm()}
      </styles.ColStyled>
    </styles.RowStyled>
  );
}
