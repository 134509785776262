import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import AllDentists from './AllDentists';
import Assessments from './Assessments';

export default function() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <AllDentists />
      </Route>
      <Route path={`${path}/assessments/:dentistEmail`}>
        <Assessments />
      </Route>
    </Switch>
  );
}
