import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import AllUpdates from './AllUpdates';
import UploadFirmware from './UploadFirmware';

export default function() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <AllUpdates />
      </Route>
      <Route path={`${path}/upload`}>
        <UploadFirmware />
      </Route>
    </Switch>
  );
}
