import firebase from 'firebase/app';
import 'firebase/firestore';
import { differenceInMilliseconds } from 'date-fns';

import { getDurationFromMilliseconds } from './app';

export async function getDentists() {
  const dentists = [];

  const dentistsSnapshot = await firebase
    .firestore()
    .collection('users')
    .where('type', '==', 'dentist')
    .get();

  for (const doc of dentistsSnapshot.docs) {
    const dentist = doc.data();

    const assessmentsPerformed = await getNrOfAssessmentsPerformed(
      dentist.email
    );

    const sessionSnapshot = await firebase
      .firestore()
      .collection('users')
      .doc(doc.id)
      .collection('sessions')
      .get();

    dentist['uid'] = doc.id;
    dentist['assessmentsPerformed'] = assessmentsPerformed;
    dentist['hasSubscription'] = !!dentist.currentPlan;
    dentist['sessionsPerformed'] = sessionSnapshot.size;

    dentists.push(dentist);
  }

  return dentists;
}

async function getNrOfAssessmentsPerformed(dentistEmail) {
  const assessmentsSnapshot = await firebase
    .firestore()
    .collection('assessments')
    .where('dentistEmail', '==', dentistEmail)
    .get();

  return assessmentsSnapshot.size;
}
export async function getAssessmentsPerformed(dentistEmail) {
  const assessmentsPerformed = [];

  const assessmentsSnapshot = await firebase
    .firestore()
    .collection('assessments')
    .where('dentistEmail', '==', dentistEmail)
    .get();

  for (const doc of assessmentsSnapshot.docs) {
    const assessment = doc.data();

    const patientRef = await firebase
      .firestore()
      .collection('users')
      .where('email', '==', assessment.patientEmail)
      .get();

    if (patientRef.docs.length >= 1) {
      const patientSessionsRef = await firebase
        .firestore()
        .collection('users')
        .doc(patientRef.docs[0].id)
        .collection('sessions')
        .get();

      let totalSessionsDuration = patientSessionsRef.docs.reduce(
        (totalSessionDuration, doc) =>
          totalSessionDuration +
          differenceInMilliseconds(
            doc.data().stopTime.toDate(),
            doc.data().startTime.toDate()
          ),
        0
      );

      totalSessionsDuration = getDurationFromMilliseconds(
        totalSessionsDuration
      );

      assessmentsPerformed.push({
        patientId: patientRef.docs[0].id,
        startTime: assessment.startTime.toDate(),
        endTime: assessment.endTime && assessment.endTime.toDate(),
        nrOfSessions: patientSessionsRef.size,
        totalSessionsDuration: totalSessionsDuration.distance,
      });
    } else {
      assessmentsPerformed.push({
        patientId: null,
        startTime: null,
        endTime: null,
        nrOfSessions: null,
        totalSessionsDuration: null,
      });
    }
  }

  return assessmentsPerformed;
}
