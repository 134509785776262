import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import logo from '../images/logo.png';

const Header = styled.header`
  background: white;
  margin-bottom: 1.45rem;
  height: 75px;
  box-shadow: 0 2px 4px rgba(81, 107, 152, 0.16);
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100%;
`;

export default function() {
  return (
    <Header>
      <HeaderWrapper>
        <Link
          to="/"
          style={{
            display: 'block',
            height: '35px',
          }}
        >
          <img src={logo} style={{ height: 35 }} alt={'BruxRelief Logo'} />
        </Link>
      </HeaderWrapper>
    </Header>
  );
}
