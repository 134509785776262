import styled from 'styled-components';
import { Col, Row } from 'antd';

export default {
  RowStyled: styled(Row)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,

  ColStyled: styled(Col)`
    border-radius: 4px;
    border: lightgray dashed 1px;
    padding: 40px 0;
  `,

  ContentWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
};
