import React, { useState, useEffect } from 'react';
import { Table, Typography, Tag } from 'antd';
import { useParams } from 'react-router-dom';
import { differenceInDays, format } from 'date-fns';

import { getAssessmentsPerformed } from '../../../modules';

import styles from './styles';

const { Title } = Typography;

export default function() {
  // navigation
  const { dentistEmail } = useParams();

  // state
  const [assessments, setAssessments] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);

      let newAssessments = await getAssessmentsPerformed(dentistEmail);

      if (newAssessments.length > 0) {
        newAssessments = newAssessments.map((object, index) => ({
          ...object,
          key: index + 1,
        }));

        setAssessments(newAssessments);
      }

      setFetching(false);
    };

    document.title = 'Assessments | BruxRelief';

    fetchData();
  }, [dentistEmail]);

  const columns = [
    {
      title: 'Patient UID',
      dataIndex: 'patientId',
      key: 'patientId',
      render: patientId => (
        <span>
          {patientId ? patientId : <Tag color="volcano">Not SignedUp Yet</Tag>}
        </span>
      ),
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
      render: startTime => (
        <span>
          {startTime ? format(startTime, 'MMMM do yyyy, h:m a') : '-'}
        </span>
      ),
    },
    {
      title: 'Device Is Returned',
      dataIndex: 'endTime',
      key: 'endTime',
      render: (endTime, record) => (
        <span>
          {endTime !== null ? (
            <Tag color={endTime ? 'green' : 'volcano'}>
              {endTime
                ? `Returned after ${differenceInDays(
                    endTime,
                    record.startTime
                  )} days`
                : 'Not returned'}
            </Tag>
          ) : (
            '-'
          )}
        </span>
      ),
    },
    {
      title: 'Sessions Performed',
      dataIndex: 'nrOfSessions',
      key: 'nrOfSessions',
    },
    {
      title: 'Total Sessions Duration',
      dataIndex: 'totalSessionsDuration',
      key: 'totalSessionsDuration',
    },
  ];

  return (
    <styles.RowStyled>
      <Title level={2} style={{ margin: '75px 0 50px 0' }}>
        {'Assessments'}
      </Title>
      <styles.ColStyled span={18}>
        <styles.ContentWrapper>
          <Table
            columns={columns}
            dataSource={assessments}
            pagination={false}
            loading={fetching}
            style={{ width: '100%' }}
          />
        </styles.ContentWrapper>
      </styles.ColStyled>
    </styles.RowStyled>
  );
}
