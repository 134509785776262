import React, { useEffect, useState } from 'react';
import { Button, message, Table, Tag, Typography } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import axios from 'axios';

import styles from './styles';

import { API_URL } from '../../../config';

const { Title } = Typography;

export default function() {
  // navigation
  const { path } = useRouteMatch();

  // state
  const [updates, setUpdates] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);

      let newUpdates = await getAllUpdates();

      if (newUpdates.length > 0) {
        newUpdates = newUpdates.map((object, index) => ({
          ...object,
          key: index + 1,
        }));

        setUpdates(newUpdates);
      }

      setFetching(false);
    };

    document.title = 'Firmware Updates | BruxRelief';

    fetchData();
  }, []);

  const columns = [
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: 'File Name',
      dataIndex: 'fileName',
      key: 'fileName',
    },
    {
      title: 'Channels',
      dataIndex: 'channels',
      key: 'channels',
      render: channels => (
        <span>
          {channels.map(channel => {
            let color = 'grey';

            if (channel === 'Beta') {
              color = 'volcano';
            } else if (channel === 'Production') {
              color = 'green';
            }

            return (
              <Tag color={color} key={channel}>
                {channel.toUpperCase()}
              </Tag>
            );
          })}
        </span>
      ),
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
      render: url => <a href={url}>{url}</a>,
    },
  ];
  const getAllUpdates = async () => {
    try {
      const response = await axios.get(`${API_URL}/dfu/updates`);
      return response.data;
    } catch (error) {
      console.warn(error);
      message.error(
        error && error.response ? error.response.data : error.toString()
      );
      return [];
    }
  };

  return (
    <styles.RowStyled>
      <Title level={2} style={{ margin: '75px 0 50px 0' }}>
        {'Firmware Updates'}
      </Title>
      <styles.ColStyled span={18}>
        <styles.ContentWrapper>
          <styles.TableHeader>
            <Button type="primary">
              <Link to={`${path}/upload`}>{'Upload Firmware'}</Link>
            </Button>
            <styles.LatestUpdateWrapper>
              <Title level={4} type="secondary" style={{ margin: 0 }}>
                {'Latest Version:'}
              </Title>
              {updates.length > 0 ? (
                <Tag color={'blue'} style={{ margin: '0 0 0 5px' }}>
                  <a href={updates[0].url}>{updates[0].version}</a>
                </Tag>
              ) : (
                <Tag color={'volcano'} style={{ margin: '0 0 0 5px' }}>
                  {'No Updates'}
                </Tag>
              )}
            </styles.LatestUpdateWrapper>
          </styles.TableHeader>
          <Table
            columns={columns}
            dataSource={updates}
            pagination={false}
            loading={fetching}
            style={{ width: '100%' }}
          />
        </styles.ContentWrapper>
      </styles.ColStyled>
    </styles.RowStyled>
  );
}
