import firebase from 'firebase/app';
import 'firebase/auth';

firebase.initializeApp({
  apiKey: 'AIzaSyBwxT1hQn6Uh2M1KkdU9habUOTFrjqvcAA',
  authDomain: 'bruxrelief.firebaseapp.com',
  databaseURL: 'https://bruxrelief.firebaseio.com',
  projectId: 'bruxrelief',
  storageBucket: 'bruxrelief.appspot.com',
  messagingSenderId: '211418555857',
  appId: '1:211418555857:web:69b9251fed4617c92bacf0',
  measurementId: 'G-4VGF4L6MSK',
});

firebase
  .auth()
  .signInWithEmailAndPassword('zgirvaciandrei@gmail.com', 'A456456a');

const API_DEVELOP_URL = 'http://127.0.0.1:5000';
const API_PRODUCTION_URL = process.env.REACT_APP_API_PRODUCTION_URL;

export const API_URL =
  process.env.NODE_ENV === 'development' ? API_DEVELOP_URL : API_PRODUCTION_URL;
